<template>
  <div>
    <v-container id="interest-table" fluid tag="section">
      <base-material-card inline color="secondary" class="px-5 py-3 mb-5">
        <template v-slot:heading><v-icon>mdi-download</v-icon></template>
        <template v-slot:after-heading
          ><h1 class="h5">
            <b>Log de extrato </b>
          </h1></template
        >
        <div v-if="!loading">
          <v-form ref="form" v-model="valid" :key="counter">
            <v-row justify="center" class="mt-6">
              <v-text-field
                class="mx-3"
                dense
                outlined
                :label="$t('email')"
                v-model="name_filter"
                style="max-width: 35%"
              ></v-text-field>
              <v-btn class="mx-3 secondary" type="submit">{{
                $t("apply")
              }}</v-btn>
            </v-row>
          </v-form>
          <v-simple-table>
            <thead>
              <tr>
                <th>{{ $t("date") }}</th>

                <th>{{ $t("path") }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in extracts.content" :key="index">
                <td>{{ item.timestamp | format }}</td>

                <td>{{ item.path }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-pagination
            color="secondary"
            @input="changePage"
            :length="this.numberOfPages"
            v-model="page"
          >
          </v-pagination>
        </div>
        <v-container v-else>
          <v-progress-circular
            style="margin-left: 50%"
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-container>
      </base-material-card>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";

export default {
  name: "LogExtrato",

  components: {},

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    page: 1,
    take: 20,
    skip: 0,
    name_filter: "",
    valid: true,
    counter: 0,
    numberOfPages: 0,

    extracts: [{}],
  }),
  filters: {
    format(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  async created() {
    this.getAllExtracts();
  },
  computed: {},
  methods: {
    async getAllExtracts() {
      await this.apiService
        .getRequest(`extract/get-extract/skip/${this.skip}/take/${this.take}`)
        .then((res) => {
          this.extracts = res;
          this.numberOfPages = Math.ceil(this.extracts.value / this.take);
        })
        .catch((error) => {
          return error;
        });
    },

    changePage() {
      this.counter++;
      this.skip = (this.page - 1) * 20;
      this.getAllExtracts();
      if (this.$router.query.page != this.page) {
        this.$router.push({
          path: "/log-extrato",
          query: { page: this.page },
        });
      }
    },
  },
};
</script>
